export enum EDeliveryIntervalValues {
  TWO_WEEKS = 'twoWeeks',
  THREE_WEEKS = 'threeWeeks',
  MONTHLY = 'fourWeeks',
  FIVE_WEEKS = 'fiveWeeks',
  SIX_WEEKS = 'sixWeeks',
  TWO_MONTHS = 'twoMonths',
  QUARTERLY = 'quarterly',
  HALFYEARLY = 'halfYearly',
  NINE_MONTHS = 'nineMonths',
}

export enum EDeliveryDaysValues {
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
}

export interface IDeliveryInterval {
  label: string;
  value: string;
}

export interface IDeliveryDay {
  label: string;
  value: string;
}
