import { useCartStore } from '~/stores/useCart';
import {
  EDeliveryIntervalValues,
  EDeliveryDaysValues,
  type IDeliveryInterval,
  type IDeliveryDay,
} from '@/@types/product-subscription';
import type { IListItem } from '~/@types/bullet-list';
import {
  useDialogStore,
  DialogIdent,
  DialogResponses,
} from '~/stores/useDialogStore';
import type {
  ProductSubscriptionItem,
  ProductSubscriptionOrderDate,
  ProductSubscriptionOrderDates,
} from '~/server/transformers/shop/productSubscriptionListTransformer';
import type { Product } from '~/server/transformers/shop/product/types';
import { useSessionGet } from '~/composables/dataFetching/genericFetchers';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useSubscriptionBox(props: { product: Product }) {
  /** default values */
  const aboItemCount = ref(
    props.product?.productSubscription?.lowerLimit ??
      props.product?.minPurchase ??
      0,
  );
  const selectedAboInterval = ref(EDeliveryIntervalValues.MONTHLY);
  const selectedDeliveryDay = ref<string>(EDeliveryDaysValues.WEDNESDAY);

  const currentVariant = ref(props.product);
  const { t } = useTrans();
  const { addProductSubscription } = useCartStore();

  const deliveryIntervals: IDeliveryInterval[] = [
    {
      label: t('shop.orderAlarm.label.weeks', {
        interval: '2',
      }),
      value: EDeliveryIntervalValues.TWO_WEEKS,
    },
    {
      label: t('shop.orderAlarm.label.weeks', {
        interval: '3',
      }),
      value: EDeliveryIntervalValues.THREE_WEEKS,
    },
    {
      label: t('shop.orderAlarm.label.weeks', {
        interval: '4',
      }),
      value: EDeliveryIntervalValues.MONTHLY,
    },
    {
      label: t('shop.orderAlarm.label.weeks', {
        interval: '5',
      }),
      value: EDeliveryIntervalValues.FIVE_WEEKS,
    },
    {
      label: t('shop.orderAlarm.label.weeks', {
        interval: '6',
      }),
      value: EDeliveryIntervalValues.SIX_WEEKS,
    },
    {
      label: t('shop.orderAlarm.label.months', {
        interval: '2',
      }),
      value: EDeliveryIntervalValues.TWO_MONTHS,
    },
    {
      label: t('shop.orderAlarm.label.months', {
        interval: '3',
      }),
      value: EDeliveryIntervalValues.QUARTERLY,
    },
    {
      label: t('shop.orderAlarm.label.months', {
        interval: '6',
      }),
      value: EDeliveryIntervalValues.HALFYEARLY,
    },
    {
      label: t('shop.orderAlarm.label.months', {
        interval: '9',
      }),
      value: EDeliveryIntervalValues.NINE_MONTHS,
    },
  ];

  const deliveryDays: IDeliveryDay[] = [
    {
      label: t('globalWidget.monday'),
      value: EDeliveryDaysValues.MONDAY,
    },
    {
      label: t('globalWidget.tuesday'),
      value: EDeliveryDaysValues.TUESDAY,
    },
    {
      label: t('globalWidget.wednesday'),
      value: EDeliveryDaysValues.WEDNESDAY,
    },
    {
      label: t('globalWidget.thursday'),
      value: EDeliveryDaysValues.THURSDAY,
    },
    {
      label: t('globalWidget.friday'),
      value: EDeliveryDaysValues.FRIDAY,
    },
  ];

  const subscriptionListItems = ref([
    {
      text: t('shop.aboBox.productSubscription.listItem1'),
    },
    {
      text: t('shop.aboBox.productSubscription.listItem2'),
    },
    {
      text: t('shop.aboBox.productSubscription.listItem3'),
    },
  ] as IListItem[]);

  function onAddSubscriptionToCart(deliveryDay: string) {
    const deliverDayNumber: number = +deliveryDay;
    addProductSubscription(
      currentVariant.value.id,
      aboItemCount.value,
      deliverDayNumber,
      selectedAboInterval.value,
    );
  }

  async function getSubscriptionByProduct(
    productNumber: string,
  ): Promise<ProductSubscriptionItem> {
    try {
      const site = useSiteIdent();
      const result = await useSessionGet<ProductSubscriptionItem>(
        `/api/${site}/shop/product/productSubscription/productNumber/${productNumber}`,
      );
      if (result?.id) {
        return result;
      }
      return null;
    } catch (error) {
      handleLoadingError(error);
    }
  }

  return {
    aboItemCount,
    selectedAboInterval,
    deliveryIntervals,
    selectedDeliveryDay,
    deliveryDays,
    subscriptionListItems,
    onAddSubscriptionToCart,
    getSubscriptionByProduct,
  };
}

export function useSubscriptionDeliveryNow(
  subscription: ProductSubscriptionItem,
) {
  const dialogStore = useDialogStore();

  const nextOrderDate = ref(formatNextOrderDate(subscription.orderDates));

  const disabled = ref(
    checkDateDiff(subscription.deliveryDate) || subscription?.tempNotAvailable,
  );
  const triggerReload = ref(false);

  async function openDeliveryDialog(
    subscription: ProductSubscriptionItem,
    product: Product,
  ) {
    await dialogStore.openDialog(DialogIdent.SUBSCRIPTION_DELIVERY_NOW, {
      subscription: subscription,
      product: product,
    });
    triggerReload.value = true;
  }

  async function openPauseDialog(
    subscription: ProductSubscriptionItem,
    product: Product,
    disableFirst: boolean,
  ) {
    const response = await dialogStore.openDialog(
      DialogIdent.SUBSCRIPTION_PAUSE,
      {
        subscription: subscription,
        product: product,
        disableFirst: disableFirst,
      },
    );
    if (response == DialogResponses.SUCCESS) {
      triggerReload.value = true;
    }
  }

  async function openEditDialog(
    subscription: ProductSubscriptionItem,
    product: Product,
  ) {
    const response = await dialogStore.openDialog(
      DialogIdent.SUBSCRIPTION_EDIT,
      {
        subscription: subscription,
        product: product,
        triggerReload: triggerReload,
      },
    );
    if (response == DialogResponses.SUCCESS) {
      triggerReload.value = true;
    }
  }

  async function openCancelDialog(
    subscription: ProductSubscriptionItem,
    product: Product,
  ) {
    const sub = ref(subscription);
    const response = await dialogStore.openDialog(
      DialogIdent.SUBSCRIPTION_CANCEL,
      {
        subscription: sub,
        product: product,
        triggerReload: triggerReload,
      },
    );
    if (response == DialogResponses.SUCCESS) {
      triggerReload.value = true;
    }
  }

  function formatNextOrderDate(orderDates: ProductSubscriptionOrderDates) {
    const nextActiveOrderDate: ProductSubscriptionOrderDate = Object.values(
      orderDates,
    ).find((date) => {
      return date.status === true;
    });
    return new Date(nextActiveOrderDate.date).toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      weekday: 'short',
    });
  }

  // checks whether the date on which the order is placed is today or earlier
  function checkDateDiff(date: string) {
    // gets today's date and sets the time to 0 to compare it
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const dateDelivery = new Date(date);

    return dateDelivery <= dateNow;
  }

  return {
    nextOrderDate,
    disabled,
    triggerReload,
    openDeliveryDialog,
    openPauseDialog,
    formatNextOrderDate,
    checkDateDiff,
    openEditDialog,
    openCancelDialog,
  };
}
